<script setup lang="ts">
const relations = [
    'PageFeaturedArticles.articles',
    'PageFeaturedArticles.featuredArticle',
    'PageFeaturedArticlesV2.articles',
    'PageFeaturedArticlesV2.featuredArticle',
    'PageFeaturedArticlesV3.articles',
    'PageFeaturedArticlesV3.featuredArticle',
    'PageFeaturedArticlesV4.articles',
    'PageFeaturedArticlesV4.featuredArticle',
    'LandingFeaturedArticles.articles',
    'LandingFeaturedArticles.featuredArticle',
    'LandingFeaturedArticlesV2.articles',
    'LandingFeaturedArticlesV2.featuredArticle',
    'LandingThreeArticles.articles',
    'ArticleEeat.authors',
    'HomepageNews.articles',
    'HomepageNews.featuredArticle',
    'HomepageReviews.reviews',
];

const story = await useEsStoryblokStory(relations, 'story', 'home');
if (!story) {
    throw createError({
        status: 404,
    });
}
const uniqueKey = `cdn/datasource_entries/global-article-copy`;
const { data: globalArticleCopy } = await useAsyncData(
    uniqueKey,
    async () => await useEsStoryblokDatasource('global-article-copy'),
);

useInsertSeoMeta(story.value as unknown as StoryblokStoryInterface);

const { data } = await useAsyncData(
    'cdn/datasource_entries/seo-data',
    async () => await useEsStoryblokDatasource('seo-data'),
);
useInsertJsonLd(data.value);
</script>

<template>
    <storyblok-component
        v-if="story"
        :blok="story.content"
        :global-article-copy="globalArticleCopy" />
</template>
